import { useTranslations } from 'next-intl';

export const OpeningHours = () => {
  const t = useTranslations('OpeningHours');
  return (
    <table className="mt-4 table-auto md:mt-2">
      <caption>
        <b>{t('title')}</b>
      </caption>
      <tbody>
        <tr>
          <td>
            <b>{t('opening-days')}</b>
          </td>
        </tr>
        <tr>
          <td className="pl-3">{t('opening-hours')}</td>
        </tr>
      </tbody>
    </table>
  );
};
