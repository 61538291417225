import type { IconType } from 'react-icons';
import { IoCarSport } from 'react-icons/io5';

import {
  SiAcura,
  SiAlfaromeo,
  SiAstonmartin,
  SiAudi,
  SiBentley,
  SiBmw,
  SiBugatti,
  SiCadillac,
  SiChevrolet,
  SiChrysler,
  SiCitroen,
  SiDacia,
  SiFerrari,
  SiFiat,
  SiFord,
  SiHonda,
  SiHyundai,
  SiInfiniti,
  SiJaguar,
  SiJeep,
  SiKia,
  SiLamborghini,
  SiLandrover,
  SiMaserati,
  SiMazda,
  SiMclaren,
  SiMercedes,
  SiMini,
  SiMitsubishi,
  SiNissan,
  SiPeugeot,
  SiPorsche,
  SiRenault,
  SiRollsroyce,
  SiSeat,
  SiSkoda,
  SiSmart,
  SiSubaru,
  SiSuzuki,
  SiTata,
  SiTesla,
  SiToyota,
  SiVauxhall,
  SiVolkswagen,
  SiVolvo,
} from 'react-icons/si';

interface VehicleBrand {
  [key: string]: IconType;
}

export const VehicleBrands: VehicleBrand = {
  mercedes: SiMercedes,
  'mercedes-benz': SiMercedes,
  bmw: SiBmw,
  toyota: SiToyota,
  audi: SiAudi,
  ford: SiFord,
  chevrolet: SiChevrolet,
  hyundai: SiHyundai,
  volkswagen: SiVolkswagen,
  honda: SiHonda,
  nissan: SiNissan,
  ferrari: SiFerrari,
  lamborghini: SiLamborghini,
  porsche: SiPorsche,
  mazda: SiMazda,
  mitsubishi: SiMitsubishi,
  subaru: SiSubaru,
  kia: SiKia,
  jaguar: SiJaguar,
  'land rover': SiLandrover,
  volvo: SiVolvo,
  tesla: SiTesla,
  // lexus: SiLexus,
  infiniti: SiInfiniti,
  acura: SiAcura,
  'alfa romeo': SiAlfaromeo,
  'aston martin': SiAstonmartin,
  bentley: SiBentley,
  bugatti: SiBugatti,
  cadillac: SiCadillac,
  chrysler: SiChrysler,
  // dodge: SiDodge,
  fiat: SiFiat,
  jeep: SiJeep,
  // lotus: SiLotus,
  maserati: SiMaserati,
  mclaren: SiMclaren,
  mini: SiMini,
  peugeot: SiPeugeot,
  renault: SiRenault,
  'rolls-royce': SiRollsroyce,
  skoda: SiSkoda,
  suzuki: SiSuzuki,
  tata: SiTata,
  vauxhall: SiVauxhall,
  citroen: SiCitroen,
  // pagani: SiPagani, // Added famous high-performance brand
  // koenigsegg: SiKoenigsegg, // Added famous high-performance brand
  dacia: SiDacia, // Well-known in Europe
  seat: SiSeat, // Well-known Spanish brand
  // ssangyong: SiSsangYong, // South Korean automaker
  smart: SiSmart,

  generic: IoCarSport,
};
