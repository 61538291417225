import { PaginationMorePages } from './pagination-more-pages.component';
import { PaginationItem } from './pagination-item.component';

export const PaginationItems = ({
  totalPages,
  page,
  pageMore,
}: {
  totalPages: number;
  page: number;
  pageMore: number;
}) => {
  let more = true;
  return Array.from({ length: totalPages }, (_, i) => i + 1).map((i) => {
    const showMore = i > pageMore && i < totalPages - 1;
    const showClosePages = i === page || i === page - 1 || i === page + 1;

    if (showMore && !showClosePages) {
      if (!more) {
        return null;
      }

      more = false;

      return <PaginationMorePages key={i} />;
    }

    return <PaginationItem key={i} page={i} isActive={page === i} />;
  });
};
