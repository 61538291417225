import { TextInput } from '@prickle/next-ui';

import { IBilling, IContact } from '@tuning-bytes/dto';
import { TFormControl } from '@prickle/form';
import { ErrorMessages } from '@prickle/form/formik';
import { useTranslations } from 'next-intl';

interface Props {
  formProperties: TFormControl<IContact> | TFormControl<IBilling>;
  values: IContact;
  errorMessages: ErrorMessages;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}
export function ContactInfoForm({
  formProperties,
  values,
  errorMessages,
  handleChange,
}: Readonly<Props>) {
  const t = useTranslations('ContactInfoForm');
  return (
    <>
      <h2 className="text-lg font-medium text-base-content">{t('title')}</h2>

      <TextInput
        name={formProperties.email.name}
        label={{
          text: formProperties.email.label,
          required: formProperties.email.required,
          position: 'top',
        }}
        disabled={formProperties.email.disabled}
        bordered
        onChange={handleChange}
        value={values.email}
        error={errorMessages.email}
        readOnly={formProperties.email.readOnly}
      />
    </>
  );
}
