import { TCredit } from '@tuning-bytes/dto';
import { SubmitFormButton } from './submit-form-button.component';
import { useTranslations } from 'next-intl';

type Props = {
  children?: React.ReactNode;
  credit: TCredit;
};

export const CreditCoinCard = ({
  credit: { creditsNumber, price, isBestValue, id },
}: Props) => {
  const t = useTranslations('CreditsPage');
  return (
    <article
      className="flex flex-col items-center justify-center w-full gap-4 p-4 rounded-md bg-muted bg-base-300 card indicator"
      aria-label={`${creditsNumber} ${t('labels.credits')}`}
    >
      <input type="hidden" name="creditId" value={id} />
      {isBestValue && (
        <span className="indicator-item indicator-top indicator-center badge badge-secondary">
          {t('labels.best-value')}
        </span>
      )}
      <h3 className="text-2xl font-bold">{creditsNumber}</h3>
      <p className="text-muted-foreground">{t('labels.credits')}</p>
      <SubmitFormButton color="neutral">
        {t('actions.buy', {
          price,
        })}
      </SubmitFormButton>
    </article>
  );
};
