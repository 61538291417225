import { CookieConsent } from '@prickle/next-ui';
import { useTranslations } from 'next-intl';

export const CookieBanner = () => {
  const t = useTranslations('CookieBanner');
  return (
    <CookieConsent
      isEssentialBanner
      essentialBanner={{
        description: t('description'),
        accept: t('actions.accept'),
        link: t('links.privacy'),
      }}
      privacyPolicyHref="#"
    />
  );
};
