import { PaginationNextButton } from './pagination-next-button.component';
import { PaginationPreviousButton } from './pagination-previous-button.comnponent';
import { useTranslations } from 'next-intl';
import { PaginationItems } from './pagination-items.component';

export const Pagination = ({
  page,
  totalPages,
  pageMore = 5,
}: {
  page: number;
  totalPages: number;
  pageMore?: number;
}) => {
  const t = useTranslations('Pagination');

  if (totalPages <= 1) {
    return null;
  }

  return (
    <div className="flex items-center justify-between p-2">
      <nav
        aria-label={t('aria-label')}
        className="flex justify-center w-full mx-auto"
        role="navigation"
      >
        <ul className="flex flex-row items-center justify-end gap-1">
          <PaginationPreviousButton page={page - 1} />

          <PaginationItems
            page={page}
            totalPages={totalPages}
            pageMore={pageMore}
          />

          <PaginationNextButton page={page} totalPages={totalPages} />
        </ul>
      </nav>
    </div>
  );
};
