import { VehicleBrands } from './vehicle-brands.dictionary.helper';

export function getVehicleBrandIcon(make: string) {
  const vehicleBrand = VehicleBrands[make.toLowerCase()];

  if (vehicleBrand) {
    return vehicleBrand;
  }

  return VehicleBrands.generic;
}
