import { cn } from '@prickle/ui';
import { useTranslations } from 'next-intl';
import { TbExclamationCircle } from 'react-icons/tb';

type Props = {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
};
export const DangerZoneContainer = ({ children, className }: Props) => {
  const t = useTranslations('DangerZoneContainer');
  return (
    <section
      className={cn(
        'p-4 border-2 rounded-lg shadow-xl border-error card bg-base-100',
        className,
      )}
    >
      <header className="flex items-center gap-2 text-2xl font-bold text-error">
        <TbExclamationCircle size={24} alignmentBaseline="central" />
        <h2>{t('title')}</h2>
      </header>

      <article className="mt-4 space-y-4">
        <p>{t('description')}</p>
        {children}
      </article>
    </section>
  );
};
