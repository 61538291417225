import { CheckboxInput } from '@prickle/next-ui';
import { useTranslations } from 'next-intl';

interface UserMarketingAgreementProps {
  hasAccepted?: boolean;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const UserMarketingAgreement: React.FC<UserMarketingAgreementProps> = ({
  hasAccepted = false,
  handleChange,
}) => {
  const t = useTranslations('UserMarketingAgreement.form');
  return (
    <CheckboxInput
      name="newsletter"
      label={{
        text: t('newsletter'),
        position: 'right',
      }}
      color="accent"
      checked={hasAccepted}
      onChange={handleChange}
      value="true"
    />
  );
};
