import { useTranslations } from 'next-intl';

export const RangeDisplay = ({
  total,
  page,
  pageSize,
  translationKey,
  children,
}: {
  page: number;
  pageSize: number;
  total: number;
  translationKey: string;
  children?: React.ReactNode | React.ReactNode[];
}) => {
  const t = useTranslations(translationKey);
  const from = (page - 1) * pageSize + 1;
  const to = Math.min(page * pageSize, total);

  return (
    <div className="flex items-center justify-between mb-6">
      <div className="text-muted-foreground">
        {t.rich('showing-data', {
          from,
          to,
          total,
          important: (chunk) => <strong>{chunk}</strong>,
        })}
      </div>
      <div className="flex items-center gap-4">{children}</div>
    </div>
  );
};
