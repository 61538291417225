import { Alert } from '@prickle/next-ui';
import { useTranslations } from 'next-intl';

export const InsufficientCredits = () => {
  const t = useTranslations('Messages');
  return (
    <Alert color="warning">
      <span className="text-sm">{t('insufficient-credits')}</span>
    </Alert>
  );
};
