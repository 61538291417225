import { TFormControl } from '@prickle/form';
import { ErrorMessages } from '@prickle/form/formik';
import {
  CountrySelection,
  PhoneInput,
  RadioGroupInput,
  TextInput,
} from '@prickle/next-ui';
import { IBillingInformation } from '@tuning-bytes/dto';
import { useTranslations } from 'next-intl';

interface Props {
  formProperties: TFormControl<IBillingInformation>;
  values: IBillingInformation;
  errorMessages: ErrorMessages;
  handleChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => void;
}

export function BillingInfoForm({
  formProperties,
  values,
  errorMessages,
  handleChange,
}: Readonly<Props>) {
  const t = useTranslations('BillingInfoForm');
  return (
    <>
      <h2 className="text-lg font-medium text-gray-900">{t('title')}</h2>

      <RadioGroupInput
        items={[
          {
            value: formProperties.clientType.items?.particular?.value ?? '',
            label: {
              text: formProperties.clientType.items?.particular?.label ?? '',
            },
          },
          {
            value: formProperties.clientType.items?.company?.value ?? '',
            label: {
              text: formProperties.clientType.items?.company?.label ?? '',
            },
          },
        ]}
        direction="column"
        name={formProperties.clientType.name}
        value={values.clientType}
        onChange={handleChange}
        error={errorMessages.clientType}
      />

      {formProperties.company?.required && (
        <TextInput
          bordered
          name={formProperties.company?.name}
          label={{
            text: formProperties.company?.label,
            required: formProperties.company?.required,
            position: 'top',
          }}
          onChange={handleChange}
          error={errorMessages.company}
          value={values.company}
        />
      )}

      {formProperties.firstName.required && (
        <TextInput
          bordered
          name={formProperties.firstName.name}
          label={{
            text: formProperties.firstName.label,
            required: formProperties.firstName.required,
            position: 'top',
          }}
          onChange={handleChange}
          error={errorMessages.firstName}
          value={values.firstName}
        />
      )}

      {formProperties.lastName.required && (
        <TextInput
          bordered
          name={formProperties.lastName.name}
          label={{
            text: formProperties.lastName.label,
            required: formProperties.lastName.required,
            position: 'top',
          }}
          onChange={handleChange}
          error={errorMessages.lastName}
          value={values.lastName}
        />
      )}

      <TextInput
        bordered
        name={formProperties.address.name}
        label={{
          text: formProperties.address.label,
          required: formProperties.address.required,
          position: 'top',
        }}
        onChange={handleChange}
        error={errorMessages.address}
        value={values.address}
      />

      <TextInput
        bordered
        name={formProperties.apartment.name}
        label={{
          text: formProperties.apartment.label,
          required: formProperties.apartment.required,
          position: 'top',
        }}
        onChange={handleChange}
        error={errorMessages.apartment}
        value={values.apartment}
      />

      <CountrySelection
        bordered
        name={formProperties.country.name}
        onChange={handleChange}
        value={values.country}
        label={{
          text: formProperties.country.label,
          required: formProperties.country.required,
          position: 'top',
        }}
        error={errorMessages.country}
      />

      <TextInput
        bordered
        name={formProperties.province.name}
        label={{
          text: formProperties.province.label,
          required: formProperties.province.required,
          position: 'top',
        }}
        onChange={handleChange}
        error={errorMessages.province}
        value={values.province}
      />

      <TextInput
        bordered
        name={formProperties.postalCode.name}
        label={{
          text: formProperties.postalCode.label,
          required: formProperties.postalCode.required,
          position: 'top',
        }}
        onChange={handleChange}
        error={errorMessages.postalCode}
        value={values.postalCode}
      />

      <PhoneInput
        name={formProperties.phone.name}
        onChange={handleChange}
        value={values.phone}
        label={{
          text: formProperties.phone.label,
          required: formProperties.phone.required,
          position: 'top',
        }}
        error={errorMessages.phone}
      />

      {formProperties.taxNumber && (
        <TextInput
          bordered
          name={formProperties.taxNumber.name}
          label={{
            text: formProperties.taxNumber.label,
            required: formProperties.taxNumber.required,
            position: 'top',
          }}
          onChange={handleChange}
          error={errorMessages.taxNumber}
          value={values.taxNumber}
        />
      )}
    </>
  );
}
